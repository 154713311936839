<template>
  <v-row no-gutters class="ml-2 mt-7">
    <standbyDialog :localLoadingDialog="loading"></standbyDialog>
    <v-col class="ml-1">
      <remoteSearch
        v-if="!currentTable.removeRemoteSearch"
        v-model="explorerName"
        :url="currentTable.tableUrl"
        placeholder="Enter Explorer Name"
        label="Explorer Name"
        id="explorerRemoteFilter"
        :disabled="disabled"
      ></remoteSearch>
    </v-col>
    <v-col
      class="ml-1"
      v-if="$vuetify.breakpoint.smAndUp"
      md="2"
      style="min-width: 150px; max-width: 150px"
    >
      <dateTimePicker
        title="From"
        v-model="fromTime"
        @input="searchBackendTests"
        :fieldAttrInput="{ class: '', disabled: disabled }"
        :maxDate="0"
      ></dateTimePicker>
    </v-col>
    <v-col
      class="ml-1"
      v-if="$vuetify.breakpoint.smAndUp"
      md="2"
      style="min-width: 150px; max-width: 150px"
    >
      <dateTimePicker
        title="To"
        v-model="untilTime"
        @input="searchBackendTests"
        :fieldAttrInput="{ class: '', disabled: disabled }"
        :maxDate="0"
      ></dateTimePicker>
    </v-col>
    <v-col md="2" class="ml-1" style="max-width: 140px">
      <associatedOption
        type="select"
        :fieldAttrInput="{
          id: 'timepickerSelectOption',style:'width:120px'
        }"
        :fieldAttributes="{
          ...currentTable['timepickerSelectOption'],
          ignoreEmptyAdd: true,
        }"
        :value="range"
        @input="setRange"
        :disabled="disabled"
      ></associatedOption>
    </v-col>

    <v-col class="ml-1 mr-1">
      <primaryButton
        type="button"
        @click="searchBackendTests()"
        label="Search Range"
        icon="mdi-magnify"
        color="primary"
        :disabled="disabled"
        :loading="loading"
        shrink="mdAndDown"
      ></primaryButton>
    </v-col>
  </v-row>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import dateTimePicker from "@/commonComponents/legacyDateTimePicker.vue";
import remoteSearch from "@/components/legacy/remoteSearch.vue";
import standbyDialog from "@/commonComponents/standbyDialog.vue";

export default {
  props: ["currentTable"],
  components: {
    dateTimePicker,
    primaryButton,
    associatedOption,
    remoteSearch,
    standbyDialog,
  },

  data() {
    return {
      options: [],
      value: "",
      disabled: false,
      loading: false,
      gettingOptions: false,
      localName: "",
      path: "",
      range: "",
      localLoadingDialog: false,
    };
  },
  created() {
    this.disabled = false;
    this.loading = false;
    this.localName = this.currentTable.explorerName;
    if (this.explorerName != "") {
      this.options = [this.explorerName];
    }
   // let shortURL = this.$route.fullPath;
    let shortURL = this.currentTable.tableShortUrl;
    shortURL = shortURL.replace("/", "");
    this.path = shortURL;
    //  this.$set(this.currentTable, "tableUrl", "serve.php" + this.path);
    //    this.$set(this.currentTable, "tableShortUrl",  this.path);
    //   this.$store.commit("tableChanged", this.currentTable);
    // console.log(shortURL)

    let range = "";
    if (
      typeof this.$route.query.range != "undefined" &&
      this.$route.query.range != ""
    ) {
      range = this.$route.query.range;
    } else if (
      typeof this.currentTable["timepickerSelectOption"].value != "undefined"
    ) {
      range = this.currentTable["timepickerSelectOption"].value;
      this.updateRoute("range", range);
    }
    this.range = range;
  },
  watch: {
    "currentTable.data": function () {
      this.disabled = false;
      this.loading = false;
    },
    // range: function (newValue) {
    //   if (newValue != "" && this.explorerName != "") {
    //     this.disabled = true;
    //     this.loading = true;
    //   }
    // },
  },
  methods: {
    updateRoute(parameter, value) {
      let regexString = "(" + parameter + "=).*?(&|$)";
      const regex = new RegExp(regexString, "g");
      //let newUrl;
      if (typeof value == "undefined") {
        value = "";
      }
      if (this.path.match(regex) != null) {
        // newUrl = this.currentTable.tableUrl.replace(regex, "$1" + value + "$2");

        this.path = this.path.replace(regex, "$1" + value + "$2");
      } else {
        // newUrl = this.currentTable.tableUrl + "&" + parameter + "=" + value;
        this.path = this.path + "&" + parameter + "=" + value;
      }
      this.$set(this.currentTable, "tableUrl", "serve.php" + this.path);
      this.$set(this.currentTable, "tableShortUrl", this.path);
      this.$store.commit("tableChanged", this.currentTable);
      if (!this.currentTable.disableUpdateUrl) {
        history.pushState({}, null, this.path);
      }
      //this.searchBackendTests();
    },
    searchBackendTests() {
      let triggerTime = new Date();
      this.$set(this.currentTable, "triggerTime", triggerTime);
      if (this.explorerName == "") {
        let table = this.currentTable;
        this.$set(table, "data", []);
        this.$store.commit("tableChanged", table);
      } else {
        if (!this.loading) {
          this.disabled = true;
          this.loading = true;

          var options = {
            requestType: "ajax",
          };

          let caller = this;
          //let shortURL = this.$route.fullPath;
          //shortURL = shortURL.replace("/", "");
          let url = this.currentTable.tableUrl;
          this.frameworkAxiosRequest({
            method: 'post',
            url: url,
            data: options,
          })
              .then(function (response) {
              let table = caller.currentTable;
              caller.$set(table, "data", response.data.result.table.data);
              caller.$set(
                table,
                "reloadContent",
                response.data.result.table.reloadContent
              );
              caller.$store.commit("tableChanged", table);
              caller.disabled = false;
              caller.loading = false;
            })
            .catch(function (response) {
              console.log(response);
            });
        }
      }
    },

    getRange(rangeValue) {
      let untilTime = Math.floor(new Date().getTime() / 1000);
      let currentTable = this.currentTable;
      this.$set(currentTable, "untilTime", untilTime);
      this.updateRoute("until", untilTime);

      let date = new Date();
      let intRangeValue = Number(rangeValue);
      if (!Number.isInteger(intRangeValue)) {
        var hoursTime = date.getHours() - Math.floor(intRangeValue * 24);
        date.setHours(hoursTime);
      } else {
        if (rangeValue == "0") {
          //today!
          date.setHours(0, 0, 0, 0);
        } else {
          //custom!
          date.setDate(date.getDate() - rangeValue);
        }
      }
      // console.log(rangeValue);
      let fromTime = Math.floor(date.getTime() / 1000);

      this.$set(currentTable, "fromTime", fromTime);
      this.updateRoute("from", fromTime);

      this.$store.commit("tableChanged", currentTable);
      this.range = rangeValue;
      // this.searchRange();
    },
    updateRange(newVal) {
      //this.updateRoute("until", "");

      let currentTable = this.currentTable;
      this.$set(currentTable.timepickerSelectOption, "value", newVal);
      this.$store.commit("tableChanged", currentTable);
      if (newVal != "custom") {
        this.getRange(newVal);
      }

      this.updateRoute("range", newVal);
    },
    setRange(newVal) {
      this.updateRange(newVal);
      this.searchBackendTests();
    },
  },

  computed: {
    explorerName: {
      get() {
        let name = "";
        if (typeof this.localName != "undefined" && this.localName != "") {
          name = this.localName;
        } else if (
          typeof this.$route.query.name != "undefined" &&
          this.$route.query.name != ""
        ) {
          name = this.$route.query.name;
        }
        return name;
      },
      set(newVal) {
        this.localName = newVal;
        this.updateRoute("name", newVal);
        this.searchBackendTests();
      },
    },

    url() {
      let url = "";
      if (typeof this.currentTable["tableUrl"] != "undefined") {
        url = this.currentTable["tableUrl"];
      }
      return url;
    },

    rangeSearchIndex() {
      let rangeSearchIndex = "";
      if (typeof this.currentTable["rangeSearchIndex"] != "undefined") {
        rangeSearchIndex = this.currentTable["rangeSearchIndex"];
      }
      return rangeSearchIndex;
    },
    id() {
      let id = "dataTable";
      if (typeof this.currentTable["id"] != "undefined") {
        id = this.currentTable["id"];
      }
      return id;
    },
    timepicker() {
      let timepicker = "false";
      if (typeof this.currentTable["timepicker"] != "undefined") {
        timepicker = this.currentTable["timepicker"];
      }
      return timepicker;
    },
    fromTime: {
      get() {
        let fromTime = 0;
        if (typeof this.currentTable["fromTime"] != "undefined") {
          fromTime = this.currentTable["fromTime"];
        }
        this.updateRoute("from", fromTime);
        return fromTime;
      },
      set(val) {
        let currentTable = this.currentTable;
        this.$set(currentTable, "fromTime", val);
        this.$set(currentTable, "dateChange", true);
        this.$set(currentTable.timepickerSelectOption, "value", "custom");
        this.range = "custom";
        this.updateRange("custom");
        this.$store.commit("tableChanged", currentTable);
        this.updateRoute("from", val);
      },
    },
    untilTime: {
      get() {
        let untilTime = 0;
        if (
          typeof this.currentTable["untilTime"] != "undefined" &&
          this.currentTable["untilTime"] != ""
        ) {
          untilTime = this.currentTable["untilTime"];
        }
        this.updateRoute("until", untilTime);
        return untilTime;
      },
      set(val) {
        let currentTable = this.currentTable;
        this.$set(currentTable, "untilTime", val);
        this.$set(currentTable, "dateChange", true);
        this.$set(currentTable.timepickerSelectOption, "value", "custom");
        this.range = "custom";
        this.updateRange("custom");
        this.$store.commit("tableChanged", currentTable);
        this.updateRoute("until", val);
      },
    },
    mindate() {
      let mindate = "";
      if (typeof this.currentTable["mindate"] != "undefined") {
        mindate = this.currentTable["mindate"];
      }
      return mindate;
    },
    maxDate() {
      let maxDate = "";
      if (typeof this.currentTable["maxDate"] != "undefined") {
        maxDate = this.currentTable["maxDate"];
      }
      return maxDate;
    },
  },
};
</script>