<template>
  <div>
    <v-autocomplete
      v-model="localValue"
      :items="personOptions"
      :loading="loading"
      :search-input.sync="search"
      hide-no-data
      item-text="name"
      item-value="id"
      :label="label"
      :placeholder="placeholder"
      clearable
      return-object
      outlined
      dense
      :id="id"
      :disabled="disabled"
    >
      <template slot="item" slot-scope="{ parent, item }">
        <v-list-item-content
          :id="id + '_' + item"
          :data-type="id + '_optionsList'"
        >
          <v-list-item-title v-html="parent.genFilteredText(item)">
          </v-list-item-title>
        </v-list-item-content> </template
    ></v-autocomplete>
  </div>
</template>
<script>
import debounce from "debounce";

export default {
  props: ["url", "value", "placeholder", "label", "id","disabled"],

  data() {
    return {
      personOptions: [],
      search: "",
      loading: false,
    };
  },
  created() {
    if (this.localValue != "") {
      debounce(this.makeSearch, 500)(this.localValue, this);
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    makeSearch: async (value, self) => {
      // Handle empty value
      if (!value) {
        self.personOptions = [];
        self.value = "";
      }
      // Items have already been requested
      if (self.loading) {
        return;
      }

      self.loading = true;

      self
        .axios({
          method: "post",
          url: self.url,
          data: {
            requestType: "ajax",
            function: "getExplorerList",
            name: value,
          },
        })
        .then((response) => {
          self.personOptions = response.data.result.json.explorerList;
        })
        .catch(() => {})
        .finally(() => (self.loading = false));
    },
  },
  watch: {
    search(value) {
      if (!value) {
        return;
      }
       debounce(this.makeSearch, 500)(value, this);
    },

  },
};
</script>